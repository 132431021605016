<template>
    <BoxMensagem :mostrar="mostrarBox" :tipo="tipoBox" :paragrafo="paragrafoBox" :titulo="tituloBox" @fechar-modal="fecharBox" :parada="paradaBox"/>
  <div class="box-form">
    <form id="form-initial" @submit="enviarForm">
        <SubTitle :titulo="'Formulário de SAC'" :theme="'dark'"/>
        <h3>Preencha o formulário para abrir chamado</h3>

        <div class="flex input-label">
            <div class="input" ref="nomeCompleto">
                <label for="nome">Nome Completo</label>
                <input type="text" v-model="nome" placeholder="Seu nome completo" id="nome" @input="verificarNome" @blur="verificarNome" @change="verificarNome">
                <span class="chamada-error" v-show="errorNome">Por favor, seu nome precisa ser completo.</span>
            </div><!--input-->

            <div class="input" ref="emailCompleto">
                <label for="email">E-mail</label>
                <input type="text" v-model="email" placeholder="Seu e-mail completo" id="email" @input="verificarEmail" @blur="verificarEmail" @change="verificarEmail">
                <span class="chamada-error" v-show="errorEmail">Por favor, seu e-mail não está correto.</span>
            </div><!--input-->

            <div class="input" ref="telefoneCompleto">
                <label for="telefone">Telefone</label>
                <input type="tel" v-model="telefone" placeholder="Seu número de telefone (0 0 0000-0000)" id="telefone" @input="verificarTel" @blur="verificarTel" maxlength="16">
                <span class="chamada-error" v-show="errorTel">Informe seu telefone corretamente. Está incorreto.</span>
            </div><!--input-->

            <div class="input" ref="cpfCompleto">
                <label for="cpf">CPF</label>
                <input type="tel" v-model="cpf" placeholder="Seu número de CPF (000.00.000-00)" id="cpf" @input="verificarCpf" @blur="verificarCpf" maxlength="14">
                <span class="chamada-error" v-show="errorCpf">Informe seu CPF corretamente. Está incorreto.</span>
            </div><!--input-->

            <div class="input" ref="rgCompleto">
                <label for="rg">RG</label>
                <input type="tel" v-model="rg" placeholder="Seu número de RG (00.000.000-0)" id="rg" @input="verificarRg" @blur="verificarRg" maxlength="12">
                <span class="chamada-error" v-show="errorRg">Informe seu RG corretamente. Está incorreto.</span>
            </div><!--input-->

            <div class="input" ref="contratoCompleto">
                <label for="contrato">Valor do Contrato</label>
                <input type="tel" v-model="contrato" placeholder="Digite o Valor em Números" id="contrato" @input="verificarValorMonetario" @blur="verificarValorMonetario">
                <span class="chamada-error" v-show="errorContrato">Informe um valor acima de R$1000,00.</span>
            </div><!--input-->

            <div class="input" ref="pixCompleto">
                <label for="pix">Chave PIX para caso de estorno</label>
                <input type="text" v-model="pix" placeholder="Informe sua chave PIX" id="pix" @input="verificarPix" @blur="verificarPix" @change="verificarPix">
                <span class="chamada-error" v-show="errorPix">Informe o PIX corretamente.</span>
            </div><!--input-->

            <div class="input" ref="vendedorCompleto">
                <label for="vendedor">Nome do Vendedor Responsável</label>
                <input type="text" v-model="vendedor" placeholder="Adicione o nome da pessoa que te vendeu" id="vendedor" @input="verificarVendedor" @blur="verificarVendedor" @change="verificarVendedor">
                <span class="chamada-error" v-show="errorVendedor">Informe o nome do vendedor completo.</span>
            </div><!--input-->

            <div class="input" ref="franquiaCompleto">
                <label for="franquia">Onde foi fechado o contrato</label>
                <input type="text" v-model="franquia" placeholder="Informe a Franquia que foi fechado o contrato" id="franquia" @input="verificarFranquia" @blur="verificarFranquia" @change="verificarFranquia">
                <span class="chamada-error" v-show="errorFranquia">Informe o nome da franquia corretamente.</span>
            </div><!--input-->

            <div class="input" ref="cidadeCompleto">
                <label for="cidade">Cidade</label>
                <input type="text" v-model="cidade" placeholder="Informe sua Cidade" id="cidade" @input="verificarCidade" @blur="verificarCidade" @change="verificarCidade">
                <span class="chamada-error" v-show="errorCidade">Informe sua cidade corretamente.</span>
            </div><!--input-->

            <div class="input-radios" ref="motivoCompleto">
                <span>O que você deseja?</span>
                <div class="flex">
                    <div class="radios">
                        <input type="radio" id="desistir-contrato" name="desistir" value="Desistir do Contrato com a Empresa" @change="verificarMotivo">
                        <label for="desistir-contrato">Desistir do Contrato com a Empresa</label>
                    </div><!--radios-->

                    <div class="radios">
                        <input type="radio" id="desistir-consorcio" name="desistir" value="Desistir do Consórcio" @change="verificarMotivo">
                        <label for="desistir-consorcio">Desistir do Consórcio</label>
                    </div><!--radios-->

                    <div class="radios">
                        <input type="radio" id="servico-prestado" name="desistir" value="Serviço não está sendo prestado pela Empresa" @change="verificarMotivo">
                        <label for="servico-prestado">Serviço não está sendo prestado pela Empresa</label>
                    </div><!--radios-->

                    <div class="radios">
                        <input type="radio" id="outros" name="desistir" value="Outros" @change="verificarMotivo">
                        <label for="outros">Outros</label>
                    </div><!--radios-->
                </div><!--flex-->
                <span class="chamada-error" v-show="errorMotivo">Selecione um motivo para continuar.</span>
            </div><!--input-->
        </div><!--flex-->      

        <div class="input-submit">
            <div class="load" v-if="carregandoForm">
            </div><!--load-->

            <input type="submit" name="btn-action" id="btn-form" value="Enviar Chamado" v-if="!enviandoFormulario">
        </div><!--input-submit--> 
    </form>
  </div><!--box-form-->
</template>

<script>
import SubTitle from './SubTitle.vue';
import axios from 'axios';
import BoxMensagem from './BoxMensagem.vue';
export default {
    components: {
        SubTitle,
        BoxMensagem
    },
    data(){
        return{
            nome: '',
            errorNome: false,
            email: '',
            errorEmail: false,
            telefone: '',
            errorTel: false,
            cpf: '',
            errorCpf: false,
            rg: '',
            errorRg: false,
            contrato: '',
            errorContrato: '',
            pix: '',
            errorPix: false,
            vendedor: '',
            errorVendedor: false,
            franquia: '',
            errorFranquia: false,
            cidade: '',
            errorCidade: false,
            motivo: '',
            errorMotivo: false,
            verifyNome: false,
            verifyEmail: false,
            verifyTel: false,
            verifyCpf: false,
            verifyRg: false,
            verifyContrato: false,
            verifyPix: false,
            verifyVendedor: false,
            verifyFranquia: false,
            verifyCidade: false,
            verifyMotivo: false,
            enviandoFormulario: false,
            carregandoForm: false,
            tituloBox: '',
            paragrafoBox:'',
            tipoBox: '',
            mostrarBox: false,
            paradaBox: ''
        }
    },
    methods: {
        verificarNome(){
            const elemento = this.$refs.nomeCompleto;
            const nomeCompleto = this.nome;
            const splitNome = nomeCompleto.split(' ');
            if(splitNome.length == 1){
                this.verifyNome = false;
                this.errorNome = true;
                elemento.classList.add('error');
                return;
            }

            if(splitNome[1].length < 1){
                this.verifyNome = false;
                this.errorNome = true;
                elemento.classList.add('error');
                return;
            }

            this.verifyNome = true;
            this.errorNome = false;
            elemento.classList.remove('error');
        },
        verificarEmail(){
            const emailRegra = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const elemento = this.$refs.emailCompleto;
            if(!emailRegra.test(this.email)){
                this.verifyEmail = false;
                this.errorEmail = true;
                elemento.classList.add('error');
                return;
            }
            this.verifyEmail = true;
            this.errorEmail = false;
            elemento.classList.remove('error');
        },
        verificarTel(){
            const elemento = this.$refs.telefoneCompleto;
            var valorTelefone = this.telefone;
            valorTelefone = valorTelefone.replace(/^(\d{2})(\d)/g, '($1) $2 ');
            valorTelefone = valorTelefone.replace(/(\d{4})(\d)$/, '$1-$2');
            this.telefone = valorTelefone;
            if(this.telefone.length < 16){
                this.errorTel = true;
                this.verifyTel = false;
                elemento.classList.add('error');
                return;
            }

            this.errorTel = false;
            this.verifyTel = true;
            elemento.classList.remove('error');
        },
        verificarCpf(){
            const elemento = this.$refs.cpfCompleto;
            var valorInput = this.cpf.replace(/\D/g, '');
            valorInput = valorInput.replace(/(\d{3})(\d)/, '$1.$2'); // Primeiro bloco de 3 dígitos
            valorInput = valorInput.replace(/(\d{3})(\d)/, '$1.$2'); // Segundo bloco de 3 dígitos
            valorInput = valorInput.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Último bloco de 2 dígitos
            this.cpf = valorInput; // Atualiza o valor do input com a máscara

            var verificarValorInput = this.cpf.replace(/\D/g, '');
            if(verificarValorInput.length < 11){
                this.errorCpf = true;
                this.verifyCpf = false;
                elemento.classList.add('error');
                return;
            }
            this.errorCpf = false;
            this.verifyCpf = true;
            elemento.classList.remove('error');
        },
        verificarRg(){
            const elemento = this.$refs.rgCompleto
            var valorRg = this.rg.replace(/\D/g, '');
            valorRg = valorRg.replace(/(\d{2})(\d)/, '$1.$2');
            valorRg = valorRg.replace(/(\d{3})(\d)/, '$1.$2');
            valorRg = valorRg.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
            this.rg = valorRg;
            if(this.rg.length < 12){
                this.errorRg = true;
                this.verifyRg = false;
                elemento.classList.add('error');
                return;
            }
            this.errorRg = false;
            this.verifyRg = true;
            elemento.classList.remove('error');
        },
        verificarValorMonetario(){
            const elemento = this.$refs.contratoCompleto;
            let valor = this.contrato;
            valor = valor.replace(/\D/g, '');
            valor = valor.replace(/(\d)(\d{2})$/, '$1,$2');
            valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '.');
            this.contrato = 'R$'+valor;
            
            var valorInt = this.contrato.split(',');
            valorInt[0] = valorInt[0].replace('R$','');
            var valorMinimo = valorInt[0].split('.');
            var valorMinimoPrimario = valorMinimo[0];
            var valorMinimoSecundario = valorMinimo[1] != undefined ? valorMinimo[1] : '';
            var valorMinimoPrincipal = parseInt(valorMinimoPrimario + valorMinimoSecundario);
            if(valorMinimoPrincipal < 1000 || this.contrato == 'R$'){
                this.errorContrato = true;
                this.verifyContrato = false;
                elemento.classList.add('error');
                return;
            }

            this.errorContrato = false;
            this.verifyContrato = true;
            elemento.classList.remove('error');
        },
        verificarPix(){
            const elemento = this.$refs.pixCompleto;
            if(this.pix.length < 8){
                this.verifyPix = false;
                this.errorPix = true;
                elemento.classList.add('error');
                return;
            }

            this.verifyPix = true;
            this.errorPix = false;
            elemento.classList.remove('error');
        },
        verificarVendedor(){
            const elemento = this.$refs.vendedorCompleto;
            const nomeCompleto = this.vendedor;
            const splitNome = nomeCompleto.split(' ');
            if(splitNome.length == 1){
                this.verifyVendedor = false;
                this.errorVendedor = true;
                elemento.classList.add('error');
                return;
            }

            if(splitNome[1].length < 1){
                this.verifyVendedor = false;
                this.errorVendedor = true;
                elemento.classList.add('error');
                return;
            }

            this.verifyVendedor = true;
            this.errorVendedor = false;
            elemento.classList.remove('error');
        },
        verificarFranquia(){
            const elemento = this.$refs.franquiaCompleto;
            if(this.franquia.length < 2){
                this.verifyFranquia = false;
                this.errorFranquia = true;
                elemento.classList.add('error');
                return;
            }

            this.verifyFranquia = true;
            this.errorFranquia = false;
            elemento.classList.remove('error');
        },
        verificarCidade(){
            const elemento = this.$refs.cidadeCompleto;
            if(this.cidade.length < 3){
                this.verifyCidade = false;
                this.errorCidade = true;
                elemento.classList.add('error');
                return;
            }

            this.verifyCidade = true;
            this.errorCidade = false;
            elemento.classList.remove('error');
        },
        verificarMotivo(event){
            const elemento = this.$refs.motivoCompleto
            this.motivo = event.target.value;
            this.verifyMotivo = true;
            this.errorMotivo = false;
            elemento.classList.remove('error');
        },
        addErrorMotivo(){
            const elemento = this.$refs.motivoCompleto;
            this.errorMotivo = true;
            elemento.classList.add('error');
        },
        enviarForm(event){
            event.preventDefault();
            if(this.verifyMotivo == false){
                this.addErrorMotivo();
                this.mostrarBoxModal('Por favor, preencha os dados!','Os dados não estão preenchidos, verifique se você informou todos corretamente.','error');
                setTimeout(() => {
                    this.fecharBox();
                }, 5000)
                return;
            }

            if(!this.verifyNome || !this.verifyEmail || !this.verifyTel || !this.verifyCpf || !this.verifyRg || !this.verifyContrato || !this.verifyPix || !this.verifyVendedor || !this.verifyFranquia || !this.verifyCidade || !this.verifyMotivo){
                this.mostrarBoxModal('Por favor, preencha os dados!','Os dados não estão preenchidos, verifique se você informou todos corretamente.','error');
                setTimeout(() => {
                    this.fecharBox();
                }, 5000)
                console.log(this.verifyNome ,this.verifyEmail, this.verifyTel, this.verifyCpf, this.verifyRg, this.verifyContrato, this.verifyPix, this.verifyVendedor, this.verifyFranquia, this.verifyCidade, this.verifyMotivo);
                return;
            }

            this.enviandoFormulario = true;
            this.carregandoForm = true;
            //Enviar formulario para a API
            axios.defaults.withCredentials = true;
            axios.post('http://localhost:8081/', {'nome': this.nome, 'email':  this.email, 'telefone':this.telefone,'cpf':this.cpf,'rg':this.rg,'valor_contrato':this.contrato,'pix':this.pix, 'responsavel':this.vendedor, 'franquia':this.franquia,'cidade':this.cidade,'motivo':this.motivo,'acao':true})
            .then(response => {
                if(response.data.status == 'sucesso'){
                    this.enviandoFormulario = false;
                    this.carregandoForm =false;
                    //resetar variaveis
                    this.nome = '';
                    this.email = '';
                    this.telefone = '';
                    this.cpf = '';
                    this.rg = '';
                    this.contrato = '';
                    this.pix = '';
                    this.vendedor = '';
                    this.franquia = '';
                    this.cidade = '';
                    this.motivo = '';
                    this.verifyNome = false;
                    this.verifyEmail = false;
                    this.verifyTel = false;
                    this.verifyCpf = false;
                    this.verifyRg = false;
                    this.verifyContrato = false;
                    this.verifyPix = false;
                    this.verifyVendedor = false;
                    this.verifyFranquia = false;
                    this.verifyCidade = false;
                    this.verifyMotivo = false;
                    this.mostrarBoxModal('Tudo certo!','Recebemos a sua solicitação e em breve retornaremos o contato.','sucesso');
                    setTimeout(() => {
                        this.fecharBox();
                    }, 5000)
                }else{
                    this.enviandoFormulario = false;
                    this.carregandoForm = false;
                    this.mostrarBoxModal('Por favor, pedimos desculpa!','Estamos com problema em nosso sistema. Tente novamente mais tarde.','error');
                    setTimeout(() => {
                        this.fecharBox();
                    }, 5000);
                }
            })
        },
        mostrarBoxModal(titulo, paragrafo, tipo){
            this.paradaBox = '';
            this.tituloBox = titulo;
            this.paragrafoBox = paragrafo;
            this.mostrarBox = true;
            this.tipoBox = tipo;
        },
        fecharBox(){
            this.paradaBox = 'stop';
            setTimeout(()=> {
                this.tituloBox = '';
                this.paragrafoBox = '',
                this.tipoBox = '';
                this.mostrarBox = false;
            }, 1100)
        }
    }
}
</script>

<style scoped>
    div.box-form{
        width: 100%;
        background: var(--color-white--);
        border-radius: 15px;
        padding: 40px;
        text-align: center;
        box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.13);
    }

    div.box-form form{
        width: 95%;
        margin: 0 auto;
    }

     div.box-form form h3{
        color: var(--color-dark--);
        font-family: 'Inter', sans-serif;
        font-size: 34px;
        font-weight: 900;
        margin-bottom: 40px;
     }

    div.box-form form .input-label{
        width: 100%;
        text-align: left;
    }

    div.box-form form .input-label .input{
        width: calc(100% / 2 - 30px);
        margin-bottom: 30px;
    }


    div.box-form form .input-label .input label{
        font-size: 18px;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        margin-bottom: 20px;
        display:block;
    }

    div.box-form form .input-label .input input{
        padding: 10px 15px;
        background: var(--color-white--);
        border: 1px solid var(--color-border-input--);
        width: 100%;
        border-radius: 5px;
        color: var(--color-dark--);
        font-family: 'Inter', sans-serif;
        transition: .5s;
    }

    div.box-form form .input-label .input.error label{
        color: var(--color-error--);
    }

    div.box-form form .input-label .input.error input{
        color: var(--color-error--);
        border-color: var(--color-error--);
    }


    div.box-form form .input-label .input input:focus{
        border-color: var(--color-dark--);
        outline: none;
    }

    div.box-form form .input-label .input.error input:focus{
        border-color: var(--color-error-strong--);
    }

    div.box-form form .input-label .input input::placeholder{
        color: #C8C8C8;
    }

    div.input-radios{
        display: block;
        margin-bottom: 50px;
        width: 100%;
    }

    div.input-radios > span{
        font-size: 18px;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        margin-bottom: 20px;
        display:block;
    }

    div.input-radios.error span{
        color: var(--color-error--);
    }

    div.input-radios.error > span:last-child{
        color: var(--color-error--);
        font-size: 14px;
    }

    div.input-radios > .flex{
        width: 100%;
        justify-content: flex-start;
    }

    div.input-radios .radios{
        margin-right: 27px;
    }

    div.input-radios .radios:last-child{
        margin-right: 0px !important;
    }

    div.input-radios .radios input{
        margin-right: 10px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid #DEDEDE;
        outline-color:#DEDEDE ;
    }

    div.input-radios.error input{
        border-color: var(--color-error--);
    }

    div.input-radios .radios label{
        font-size: 14px;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        color: var(--color-dark--);
        position: relative;
        top: -1px;
    }

    div.input-radios.error .radios label{
        color: var(--color-error--);
    }

    .input-submit{
        width: 100%;
        height: 58px;
        border-radius: 5px;
        background: var(--color-pp--);
        position: relative;
        transition: .5s;
        cursor: no-drop;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .input-submit:hover{
        background: #DD6A00;
    }

    .load{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 6px solid rgba(255, 255, 255, 0.678);
        border-top-color: white;
        animation-name: loading;
        animation-duration: .5s;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    }

    @keyframes loading {
        from{
            transform: rotate(0deg);
        }to{
            transform: rotate(360deg);
        }
    }

    .input-submit input{
        width: 100%;
        height: 100%;
        z-index: 1000;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: none;
        outline: none;
        border: none;
        font-family: 'Inter',sans-serif;
        font-weight: 900;
        font-size: 22px;
        color: var(--color-white--);
        cursor: pointer;
    }

    .input-submit input:active{
        border: none;
        outline: none;
    }

    .chamada-error{
        color: var(--color-error--);
        font-size: 12px;
        font-family: 'Inter', sans-serif;
        display: inline-block;
        margin-top: 5px;
        font-weight: 500;
    }

    @media screen and (max-width: 800px) {
        div.box-form form .input-label .input{
            width: 100%;
            margin-bottom: 20px;
        }

        div.box-form form .input-label .input label{
            font-size: 16px;
        }
        div.input-radios > span{
            font-size: 16px;
        }
        div.input-radios{
            display: block;
            margin-bottom: 40px;
            width: 100%;
        }

        div.box-form form h3{
            font-size: 28px;
            margin-bottom: 30px;
        }

        .input-submit{
            height: 48px;
        }

        .input-submit input{
            font-size: 18px;
        }

    }

    @media screen and (max-width: 730px){
        div.box-form form h3{
            font-size: 24px;
            margin-bottom: 30px;
        }

        .input-submit{
            height: 40px;
        }

        .load{
            width: 20px;
            height: 20px;
            border: 4px solid rgba(255, 255, 255, 0.678);
        }
    }

    @media screen and (max-width: 500px) {
        div.box-form form h3{
            font-size: 18px;
            margin-bottom: 30px;
        }
        div.box-form{
            width: 100%;
            padding: 30px;
        }
        div.box-form form{
            width: 100%;
            margin: 0 auto;
        }
        div.box-form form .input-label .input label{
            font-size: 14px;
            margin-bottom: 15px;
        }
        div.input-radios > span{
            font-size: 14px;
            margin-bottom: 15px;
        }
        .input-submit input{
            font-size: 18px;
        }
        div.input-radios{
            margin-bottom: 30px;
        }
        div.input-radios .radios{
            margin-bottom: 5px;
        }
    }

    @media screen and (max-width: 360px) {
        div.box-form{
            padding: 20px;
        }
    }
</style>