<template>
  <div class="subtitle" :class="theme">
    <h3>{{titulo}}</h3>
  </div><!--subtitle-->
</template>

<script>
export default {
    props: {
        titulo: String,
        theme: String
    }
}
</script>

<style scoped>
    div.subtitle{
        display: inline-block;
        text-align: center;
        padding: 8px 18px;
        border: 1px solid var(--color-white--);
        border-radius: 5px;
        margin-bottom: 20px;
    }

    div.subtitle h3{
        font-size: 12px;
        font-family: 'Inter', sans-serif;
        font-weight: 600;
    }

    div.subtitle.white{
        border-color: var(--color-white--);
    }

    div.subtitle.dark{
        border-color: var(--color-dark--);
    }

    div.subtitle.white h3{
        color: var(--color-white--);
    }

     div.subtitle.dark h3{
        color: var(--color-dark--);
    }
</style>