<template>
  <div class="header-content">
    <div class="logo">
        <img src="../assets/logo-1.png" alt="">
    </div><!--logo-->

    <SubTitle :titulo="'Atendimento ao Cliente'" :theme="'white'"/>

    <h1>SAC CLIENTE</h1>

    <h5>Caro cliente, caso <span>
        você precise abrir um chamado pra tratar sobre
        desistência do contrato, rescisão ou adiantamento,
    </span> abra sua demanda
através do formulário abaixo</h5>
  </div><!--header-content-->
</template>

<script>
import SubTitle from './SubTitle.vue';
export default {
    components: {
        SubTitle
    }
}
</script>

<style scoped>
    div.logo{
        margin: 0 auto;
        margin-bottom: 70px;
        width: 198px;
        height: 69px;
    }

    div.logo img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    h1{
        color: var(--color-white--);
        font-size: 60px;
        font-family: 'Inter', sans-serif;
        font-weight: 900;
        margin-bottom: 20px;
    }

    h5{
        font-size: 30px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        color: var(--color-white--);
        max-width: 1000px;
        margin: 0 auto;
    }

    h5 span{
        font-weight: 700;
    }

    @media screen and (max-width: 800px) {
        div.logo{
            width: 158px;
            height: 59px;
            margin-bottom: 40px;
        }
        h1{
            color: var(--color-white--);
            font-size: 40px;
        }

        h5{
            font-size: 22px;
        }
    }

    @media screen and (max-width: 600px) {
        div.logo{
            width: 138px;
            height: 39px;
            margin-bottom: 30px;
        }
        h1{
            color: var(--color-white--);
            font-size: 32px;
        }

        h5{
            font-size: 18px;
        }
    }

    @media screen and (max-width: 490px) {
        div.logo{
            width: 118px;
            height: 29px;
            margin-bottom: 30px;
        }
        h1{
            color: var(--color-white--);
            font-size: 26px;
            margin-bottom: 15px;
        }

        h5{
            font-size: 16px;
        }
    }
</style>