<template>
    <section class="sessao-one">
        <div class="container">
            <HeaderContent/>
        </div><!--container-->
    </section><!--sessao-one-->

    <section class="sessao-two">
        <div class="container">
            <FormContato/>
        </div><!--container-->
    </section><!--sessao-two-->

    <footer>
        <img src="../assets/logo-2.png" alt="">
    </footer>
</template>

<script>
import HeaderContent from '../components/HeaderContent.vue';
import FormContato from '../components/FormContato.vue';
export default {
    components: {
        HeaderContent,
        FormContato
    }
}
</script>

<style scoped>
    section.sessao-one{
        padding: 60px 0px;
        padding-bottom: 320px;
        width: 100%;
        background-image: url('../assets/bg.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
    }

    section.sessao-one .container{
        text-align: center;
    }

    section.sessao-two{
        position: relative;
        top: -270px;
        height: 780px;
    }

    footer{
        width: 100%;
        text-align: center;
        padding-bottom: 30px;
    }

    footer img{
        width: 234px;
        height: auto;
        object-fit: contain;
    }

    @media screen and (max-width: 825px) {
        section.sessao-two{
        height: 820px;
    }
    }

    @media screen and (max-width: 790px) {
        section.sessao-two{
            height: 1160px;
        }
    }

    @media screen and (max-width: 700px) {
        footer img{
        width: 180px;
        }
    }
    
    @media screen and (max-width: 790px) {
        section.sessao-two{
            height: 1220px;
        }
    }

    @media screen and (max-width: 500px) {
        footer img{
        width: 140px;
        }
        section.sessao-two{
            height: 1070px;
        }
    }

    @media screen and (max-width: 460px) {
        footer img{
        width: 140px;
        }
        section.sessao-two{
            height: 1100px;
        }
    }
</style>