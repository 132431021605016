<template>
  <div class="callback sucesso" :class="[tipo, parada]" v-if="mostrar" id="modal">
        <h2>{{titulo}}</h2>
        <p>{{paragrafo}}</p>
    </div><!--callback-->
</template>

<script>
export default {
    props: {
        titulo: String,
        paragrafo: String,
        tipo: String,
        mostrar: Boolean,
        parada: String
    },
    mounted: function(){
    },
    methods: {
        fecharModal(){
            this.$emit('fechar-modal',true);  
        }
    }
}
</script>

<style scoped>
.callback{
    display: inline-block;
    padding: 15px 30px;
    max-width: 500px;
    width: 85%;
    background: #86aeff;
    border-radius: 5px;
    overflow: hidden;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    opacity: 1;
    z-index: -1;
    transition: .6s ease-in;
    z-index: 100000;
    animation-name: mostrarBox;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.callback.stop{
    opacity: 0;
    top: 0px;
    animation-name: ocultarBox;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
@keyframes mostrarBox {
    from{
        opacity: 0;
        top: 0px;
    }to{
        opacity: 1;
        top: 20px;
    }
}

@keyframes ocultarBox {
    to{
        opacity: 0;
        top: 0px;
    }from{
        opacity: 1;
        top: 20px;
    }
}


.callback h2{
    font-size: 22px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    margin-bottom: 10px;
}

.callback p{
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}

.callback.sucesso{
    background: #CAFFDC;
}

.callback.error{
    background: #FFE3E0;
}

.callback.sucesso h2{
    color: #009333;
}

.callback.error h2{
    color: #CD0000;
}


.callback p{
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}

.callback.sucesso p{
    color: #009333;
}

.callback.error p{
    color: #CD0000;
}


.callback::before{
    content: '';
    height: 100%;
    width: 2%;
    position: absolute;
    top: 0;
    left: 0;
}

.callback.sucesso::before{
    background: #009333;
}

.callback.error::before{
    background: #CD0000;
}
</style>