<template>
  <router-view/>
</template>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root{
    --color-white--: white;
    --color-dark--: black;
    --color-border-input--: #CFCFCF;
    --color-pp--: #FF7A00;
    --color-error--: #d40000;
    --color-error-strong--: #a30000;
  }

  .container{
    max-width: 1240px;
    padding: 0 2%;
    margin: 0 auto;
  }

  .flex{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }
</style>
